import React from 'react';
import Login from './MobileOtpModule/MobileOtpModule';
import LoginModule from './EmailPasswordModule';

const index = () => {
  return (
    <>
      <Login/>
      {/* <LoginModule/> */}
    </>
  );
}

export default index;
