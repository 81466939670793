// export function GenerateUniqueVal(numbers=100){
//     return Math.floor(Math.random() * numbers)
// }
const formatValue = (value) => {
    if (typeof value !== 'string') {
      return value === 0 ? '0' : value?.toString() || '';
    }
    // Remove leading zeros, but keep one zero before decimal point if needed
    const formatted = value.replace(/^0+(?=\d)/, '').replace(/^\./, '0.');
    return formatted === '' ? '0' : formatted;
};

export default formatValue;