import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import React, { useEffect ,useState} from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import Swal from 'sweetalert2';

const CoordinateDetails = ({prefix}) => {
    const {control,watch,setValue} = useFormContext();

    const [loading, setLoading] = useState(false);
    const state = watch(`${prefix}.state`);
    const mainAddress = watch(`${prefix}.address`);
    const city = watch(`${prefix}.city`);

    const [address, setAddress] = useState('');
    const fetchCoordinatesAndPincode = async () => {
        setValue(`${prefix}.latitude`, '');
        setValue(`${prefix}.longitude`, '');
        setValue(`${prefix}.pincode`, '');
        setLoading(true);
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&q=${encodeURIComponent(address)}`);
            const data = await response.json();
            console.log(`details of ${prefix}:`, data);
            if (data && data.length > 0) {
                setValue(`${prefix}.latitude`, (parseFloat(data[0].lat)).toString(), { shouldDirty: true });
                setValue(`${prefix}.longitude`, (parseFloat(data[0].lon)).toString(), { shouldDirty: true });
                
                // Extract pincode from address details
                const pincode = data[0].address.postcode;
                setValue(`${prefix}.pincode`, pincode || '', { shouldDirty: true });

                if (!pincode) {
                    Swal.fire('Pincode not found', 'The pincode could not be determined from the provided address.', 'warning');
                }
            } else {
                Swal.fire('Unable to fetch details', 'Enter a valid address', 'error');
            }
        } catch (err) {
            Swal.fire('An error occurred while fetching the details.', '', 'error');
        } finally {
            setLoading(false);
        }
    };
    useEffect(()=>{
        if (state && mainAddress && city) {
            setAddress(`${mainAddress},${city},${state}`)
        }
    },[state,mainAddress,city])
  return (
    <>
        <Button
            variant="contained"
            onClick={()=>fetchCoordinatesAndPincode(prefix)}
            disabled={loading || !mainAddress || !city || !state}
            sx={{ mb: 2 }}
        >
            {loading ? <CircularProgress size={24} /> : 'Get Coordinates'}
        </Button>
        <Box sx={{ display: 'flex', gap: '2%', my: '1%' }}>
        <Box sx={{ flex:1}}>
            <Typography sx={{ fontWeight: 'bold' }}>Pincode<span style={{color:'red'}}>*</span></Typography>
            <Controller
                name={`${prefix}.pincode`}
                control={control}
                // rules={{
                //   required: "Required",
                //   pattern: { value: /[1-9]{1}[0-9]{5}/, message: "Only numeric: not starting with 0" },
                //   minLength: { value: 6, message: "Should be 6 digits" },
                //   maxLength: { value: 6, message: "Should be 6 digits" }
                // }}
                render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    fullWidth
                    type='number'
                    size='small'
                    // disabled
                    InputProps={{ inputProps: { style: { textTransform: "uppercase" } } }}
                    error={!!error}
                />
                )}
            />
            </Box>
            <Box sx={{ flex:1}}>
            <Typography sx={{ fontWeight: 'bold' }}>Latitude<span style={{color:'red'}}>*</span></Typography>
            <Controller
                name={`${prefix}.latitude`}
                control={control}
                // rules={{
                //   required: "Required",
                //   pattern: { value: /[1-9]{1}[0-9]{5}/, message: "Only numeric: not starting with 0" },
                //   minLength: { value: 6, message: "Should be 6 digits" },
                //   maxLength: { value: 6, message: "Should be 6 digits" }
                // }}
                render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    fullWidth
                    type='number'
                    size='small'
                    disabled
                    InputProps={{ inputProps: { style: { textTransform: "uppercase" } } }}
                    error={!!error}
                />
                )}
            />
            </Box>
            <Box sx={{flex:1}}>
            <Typography sx={{width:'65%' ,fontWeight:'bold', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Longitude <span style={{color:'red'}}>*</span></Typography>
            <Controller
                name={`${prefix}.longitude`}
                control={control}
                // rules={{
                //     pattern:{
                //         value:/[1-9]{1}[0-9]{9}/,
                //         message:"only numeric : not starting with 0"
                //     },
                //     minLength:{
                //         value:10,
                //         message:"mobileNumber should be of 10 digit"
                //     },
                //     maxLength:{
                //         value:10,
                //         message:"mobileNumber should be of 10 digit"
                //     }
                // }}
                render={({field,fieldState:{error}})=>(
                    <TextField
                        {...field}
                        disabled
                        // sx={{width:'58%'}}
                        size='small'
                        fullWidth
                        error={!!error}
                        // helperText={error && error?.message}
                    />
                )}                       
            />
            </Box>
        </Box>
    </>
  );
}

export default CoordinateDetails;
