import React, { useState } from 'react';
import { Box, Button, Avatar, Typography, IconButton, Tabs, Tab, TextField, Grid, Paper, Card } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const ViewProfile = () => {
  const [coverImage, setCoverImage] = useState('https://via.placeholder.com/1200x300');
  const [profileImage, setProfileImage] = useState('https://via.placeholder.com/150');
  const [activeTab, setActiveTab] = useState(0);
  const [editMode, setEditMode] = useState(false);

  const [personalDetails, setPersonalDetails] = useState({
    firstName: 'Ram',
    middileName: 'Pratap',
    lastName: 'Singh',
    dateOfBirth: '1990-01-01',
    gender: 'Male'
  });

  const [bankDetails, setBankDetails] = useState({
    accountNumber: '1234567890',
    bankName: 'Example Bank',
    ifscCode: 'EXMP0001234'
  });

  const [contactDetails, setContactDetails] = useState({
    email: 'john.doe@example.com',
    phone: '+91 7906397578',
    address: 'B block indira nagar ,noida'
  });

  const [gallery, setGallery] = useState([
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150'
  ]);

  const handleCoverImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setCoverImage(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const handleProfileImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setProfileImage(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleSave = () => {
    // Here you would typically send the updated data to your backend
    setEditMode(false);
  };

  const renderForm = () => {
    switch (activeTab) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                value={personalDetails.firstName}
                onChange={(e) => setPersonalDetails({...personalDetails, firstName: e.target.value})}
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                value={personalDetails.lastName}
                onChange={(e) => setPersonalDetails({...personalDetails, lastName: e.target.value})}
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Date of Birth"
                type="date"
                value={personalDetails.dateOfBirth}
                onChange={(e) => setPersonalDetails({...personalDetails, dateOfBirth: e.target.value})}
                disabled={!editMode}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Gender"
                value={personalDetails.gender}
                onChange={(e) => setPersonalDetails({...personalDetails, gender: e.target.value})}
                disabled={!editMode}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Account Number"
                value={bankDetails.accountNumber}
                onChange={(e) => setBankDetails({...bankDetails, accountNumber: e.target.value})}
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Bank Name"
                value={bankDetails.bankName}
                onChange={(e) => setBankDetails({...bankDetails, bankName: e.target.value})}
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="IFSC Code"
                value={bankDetails.ifscCode}
                onChange={(e) => setBankDetails({...bankDetails, ifscCode: e.target.value})}
                disabled={!editMode}
              />
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                value={contactDetails.email}
                onChange={(e) => setContactDetails({...contactDetails, email: e.target.value})}
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Phone"
                value={contactDetails.phone}
                onChange={(e) => setContactDetails({...contactDetails, phone: e.target.value})}
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                value={contactDetails.address}
                onChange={(e) => setContactDetails({...contactDetails, address: e.target.value})}
                disabled={!editMode}
              />
            </Grid>
          </Grid>
        );
      case 3:
        return (
          <Grid container spacing={2}>
            {gallery.map((img, index) => (
              <Grid item xs={4} key={index}>
                <img src={img} alt={`Gallery ${index + 1}`} style={{ width: '100%', height: 'auto' }} />
              </Grid>
            ))}
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
      <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={6}>
                        <Typography
                            gutterBottom
                            variant='h5'
                            component='div'
                            sx={{ padding: '20px' }}
                        >
                            Profile
                        </Typography>
                    </Grid>

                </Grid>

            </Card>
      <Box sx={{ position: 'relative' }}>
        <img 
          src={coverImage} 
          alt="Cover" 
          style={{ width: '100%', height: 'auto', objectFit: 'cover' }} 
        />
        <IconButton
          sx={{
            position: 'absolute',
            bottom: 8,
            right: 8,
            backgroundColor: 'white',
            '&:hover': { backgroundColor: 'white' },
          }}
          component="label"
        >
          <input
            type="file"
            hidden
            onChange={handleCoverImageChange}
            accept="image/*"
          />
          <CameraAltIcon />
        </IconButton>
      </Box>
      <Box 
        sx={{
          width: 'fit-content',
          position: 'relative', 
          marginTop: -8, 
          mx: 'auto' 
        }}
      >
        <Avatar
          src={profileImage}
          alt="Profile"
          sx={{ width: 150, height: 150, border: '4px solid white' }}
        />
        <IconButton
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            backgroundColor: 'white',
            '&:hover': { backgroundColor: 'white' },
          }}
          component="label"
        >
          <input
            type="file"
            hidden
            onChange={handleProfileImageChange}
            accept="image/*"
          />
          <CameraAltIcon />
        </IconButton>
      </Box>
      <Box sx={{ mt: 2, p: 2, textAlign: 'center' }}>
        <Typography variant="h4">{personalDetails.firstName} {personalDetails.middileName} {personalDetails.lastName}</Typography>
        <Typography variant="body2" color="text.secondary">
          Fullstack | Web Developer 
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', mt: 2 }}>
        <Paper>
            <Tabs
            orientation="vertical"
            variant="scrollable"
            value={activeTab}
            onChange={handleTabChange}
            sx={{ borderRight: 1, borderColor: 'divider', width: '200px' }}
            >
            <Tab label="Personal Details" />
            <Tab label="Bank Details" />
            <Tab label="Contact Details" />
            <Tab label="Gallery" />
            </Tabs>
        </Paper>
        <Box sx={{ flexGrow: 1, px: 2 }}>
          <Paper elevation={3} sx={{ p: 2 }}>
            {renderForm()}
          </Paper>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            {editMode ? (
              <>
                <Button onClick={handleEditToggle} sx={{ mr: 1 }}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
              </>
            ) : (
              <Button variant="contained" color="primary" onClick={handleEditToggle}>Edit</Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewProfile;