import React, { useContext, useState } from 'react';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link, Typography } from '@mui/material';
import { Container } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import Loading from './Loading';
import { Link as RouterLink,useNavigate } from 'react-router-dom';
import LogoSection from './LogoSection';
import { useForm, Controller } from 'react-hook-form';

const LoginModule = () => {
    const navigate = useNavigate();
    const [isLoading,setIsLoading,] = useState(false);
    const { control,register, handleSubmit, setValue, formState: { errors ,isDirty},watch,reset,getValues } = useForm({
        // mode: "onChange",
    });
  return (
    <>   
        <Container component="main" maxWidth="lg">
                <Box >
                    <Grid container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ minHeight: '90vh' }}>
                        <CssBaseline />

                        <Grid
                            item
                            // xs={12}
                            // sm={8}
                            // md={5}
                            component={Paper}
                            elevation={6}
                            square
                            sx={{
                                width: { xs: "100%", sm: "75%", md: "50%" },  // Responsive widths
                                maxWidth: "600px",  // You can add a maximum width to control it on larger screens
                                // padding: 2,  // Add padding to improve spacing
                                borderRadius:'1rem'
                            }}
                        >
                            <LogoSection/>
                            <Paper
                                sx={{
                                    my: '3%',
                                    mx: 4,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    p:'3%',
                                    borderRadius:'.75rem'
                                }}
                            >
                                <Box component={'form'} sx={{width:'100%'}}>
                                    <TextField
                                        fullWidth
                                        sx={{my:'2%'}}
                                        size='small'
                                        name='email'
                                        label='Enter email'
                                        disabled={watch('phoneNumber')}
                                        {
                                            ...register(
                                                'email', {
                                                    pattern: {
                                                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                                                        message: "Enter a valid email"
                                                    },
                                                    validate: (value) => {
                                                        const phone = getValues('phoneNumber');
                                                        if (!value && !phone) {
                                                            return 'Either email or phone number is required';
                                                        }
                                                        return true;
                                                    }
                                                }
                                            )
                                        }
                                        error={!!errors.email}
                                        helperText={errors.email && errors.email?.message}
                                    />
                                    <Box sx={{my:'2%'}}>
                                        <Typography sx={{fontWeight:'bold',my:'3%',textAlign:'center'}}>OR</Typography>
                                    </Box>
                                    <Box sx={{my:'2%'}}>
                                        <TextField
                                            fullWidth
                                            type='number'
                                            size="small"
                                            label="Enter Registered Number"
                                            sx={{mb:'2%'}}
                                            disabled={watch('email')}
                                            {
                                                ...register(
                                                    'phoneNumber', {
                                                        pattern: {
                                                            value: /^[1-9]{1}[0-9]{9}$/,
                                                            message: "Only numeric, not starting with 0"
                                                        },
                                                        minLength: {
                                                            value: 10,
                                                            message: "Contact should be of 10 digits"
                                                        },
                                                        maxLength: {
                                                            value: 10,
                                                            message: "Contact should be of 10 digits"
                                                        },
                                                        validate: (value) => {
                                                            const email = getValues('email');
                                                            if (!value && !email) {
                                                                return 'Either email or phone number is required';
                                                            }
                                                            return true;
                                                        }
                                                    }
                                                )
                                            }
                                            error={!!errors.phoneNumber}
                                            helperText={errors.phoneNumber && errors.phoneNumber?.message}
                                        />
                                    </Box>
                                    <TextField
                                        fullWidth
                                        type='password'
                                        size='small'
                                        label='Enter Password*'
                                        autoComplete='new-password'
                                        name='password'
                                        {
                                            ...register(
                                                'password', {
                                                    required: "Password should not be empty",
                                                }
                                            )
                                        }
                                        error={!!errors.password}
                                        helperText={errors.password && errors.password?.message}
                                    />
                                    <Box sx={{my:'2%', textAlign:'center'}}>
                                        <Button variant='contained' color='primary' type='submit'>Login</Button>
                                    </Box>
                                    
                                </Box>
                            </Paper>
                            <Box 
                                sx={{
                                    display:"flex",
                                    justifyContent:'space-between',
                                    my: '3%',
                                    p:'3%',
                                }}
                            >
                                <Link component={RouterLink} to="/forgot-password" sx={{color:'#0866ff'}} underline='hover'>Forgot Password</Link>
                                <Typography>If not already registered? ...<span><Link component={RouterLink} to="#" underline='hover' sx={{color:'#0866ff'}}>Sign Up</Link></span></Typography>
                            </Box>
                        </Grid>
                        
                    </Grid>
                </Box>
            </Container>            
        
                    
        <ToastContainer />
        {isLoading && <Loading/>}
    </>
  );
}

export default LoginModule;
