import { TextField,
    Button,
    Grid,
    Box,
    Stack,
    Divider,
    Autocomplete,
    FormControl,
    Select,
    MenuItem,
    Typography,
    Card,FormControlLabel,Checkbox,CardContent,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper, 
    RadioGroup,
    Radio} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import formatValue from '../../../utils/formatNumber';

const ServiceCharges = () => {
    const { control, watch, setValue } = useFormContext();
    const services = watch('services');
    const isRefundable = watch('isRefundable');
    useEffect(()=>{
        if (!isRefundable) {
           setValue('refundableDuration',0);
           setValue('refundableAmount',0)
        }
    },[isRefundable])
  return (
    <Box sx={{p:'1% 2%'}}>
        <Box sx={{display:'flex',gap:'2%'}}>
            <Box sx={{flex:1}}>
                <Typography sx={{fontWeight:'bold', textTransform:'uppercase', display:'flex ',alignItems:'center',fontSize:'small'}}>visting Charge (in Rs.)<span style={{color:'red'}}>*</span></Typography>
                    <Controller
                        name='vistingCharge'
                        control={control}
                        rules={{
                            required: "required",
                            validate: (value) => {
                                const floatValue = parseFloat(value);
                                return floatValue >= 0 || 'Price should not be less than 0';
                            }
                        }}
                        render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            type="number"
                            value={formatValue(field.value)}
                            disabled={services?.length=='0'}
                            onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
                                field.onChange(formatValue(inputValue));
                            }
                            }}
                            onBlur={(e) => {
                                field.onBlur();
                                field.onChange(formatValue(e.target.value));
                            }}
                            // type="text"
                            inputMode="decimal"
                            size="small"
                            inputProps={{
                                style: { textAlign: 'center', height: '1rem' },
                                min: 0,
                                step: 0.01 // Allow two decimal places
                            }}
                            error={!!error}
                        />
                    )}  
                />                    
            </Box>
            <Box sx={{flex:1}}>
                <Typography sx={{fontWeight:'bold', textTransform:'uppercase', display:'flex ',alignItems:'center',fontSize:'small'}}>Additional Charge (in Rs.)<span style={{color:'red'}}>*</span></Typography>                        
                <Controller
                    name='additionalCharge'
                    control={control}
                    rules={{
                        required: "required",
                        validate: (value) => {
                            const floatValue = parseFloat(value);
                            return floatValue >= 0 || 'Price should not be less than 0';
                        }
                    }}
                    render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        type="number"
                        value={formatValue(field.value)}
                        disabled={services?.length=='0'}
                        onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
                            field.onChange(formatValue(inputValue));
                        }
                        }}
                        onBlur={(e) => {
                            field.onBlur();
                            field.onChange(formatValue(e.target.value));
                        }}
                        // type="text"
                        inputMode="decimal"
                        size="small"
                        fullWidth
                        inputProps={{
                            style: { textAlign: 'center', height: '1rem' },
                            min: 0,
                            step: 0.01 // Allow two decimal places
                        }}
                        error={!!error}
                    /> 
                    )}  
                />          
            </Box> 
            <Box sx={{flex:1}}>
                <Typography sx={{fontWeight:'bold', textTransform:'uppercase', display:'flex ',alignItems:'center',fontSize:'small'}}>Cancellation Charge (in %)<span style={{color:'red'}}>*</span></Typography>
                <Controller
                    name='cancellationChargeInPercent'
                    control={control}
                    rules={{
                        required: "required",
                        validate: (value) => {
                            const floatValue = parseFloat(value);
                            return floatValue >= 0 || 'Price should not be less than 0';
                        }
                    }}
                    render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        type="number"
                        value={formatValue(field.value)}
                        disabled={services?.length=='0'}
                        onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
                            field.onChange(formatValue(inputValue));
                        }
                        }}
                        onBlur={(e) => {
                            field.onBlur();
                            field.onChange(formatValue(e.target.value));
                        }}
                        // type="text"
                        inputMode="decimal"
                        size="small"
                        fullWidth
                        inputProps={{
                            style: { textAlign: 'center', height: '1rem' },
                            min: 0,
                            step: 0.01 // Allow two decimal places
                        }}
                        error={!!error}
                    /> 
                    )}  
                />                    
            </Box>
            <Box sx={{flex:1}}>
                <Typography sx={{fontWeight:'bold', textTransform:'uppercase', display:'flex ',alignItems:'center',fontSize:'small'}}>Cancellation Time Limit (in Hrs.) <span style={{color:'red'}}>*</span></Typography>                        
                <Controller
                    name='timeLimitForCancellation'
                    control={control}
                    rules={{
                        required: "required",
                        validate: (value) => {
                            const intValue = parseInt(value, 10);
                            return !isNaN(intValue) && intValue >= 0 || 'Value should be a non-negative integer';
                        }
                        }}
                        render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            disabled={services?.length=='0'}
                            value={formatValue(field.value)}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue === '' || /^\d+$/.test(inputValue)) {
                                    field.onChange(formatValue(inputValue));
                                }
                            }}
                            onBlur={(e) => {
                                field.onBlur();
                                field.onChange(formatValue(e.target.value));
                            }}
                            type="number"
                            inputMode="numeric"
                            size="small"
                            inputProps={{
                            style: { textAlign: 'center', height: '1rem',width:'3rem' },
                            min: 0,
                            step: 1 // Only allow whole numbers
                            }}
                            error={!!error}
                            // helperText={error ? error.message : ''}
                        />
                    )}  
                />          
            </Box>   
        </Box>
        <Box sx={{my:'1%',display:'flex',gap:'2%'}}>
            <Box sx={{width:'23.5%'}}>
                <Typography sx={{fontWeight:'bold', textTransform:'uppercase', display:'flex ',alignItems:'center',fontSize:'small'}}>is this Service Refundable </Typography>
                <Controller
                    name="isRefundable"
                    control={control}
                    defaultValue="false" // Default to 'No' as a string
                    render={({ field }) => (
                        <FormControl>
                        <RadioGroup
                            {...field} // Spread field properties for value and onChange
                            row
                            value={field.value === true ? "true" : "false"} // Convert to string for consistency
                            onChange={(e) => field.onChange(e.target.value === "true")} // Convert back to boolean on change
                        >
                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                            <FormControlLabel value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                        </FormControl>
                    )}
                />

            </Box>
            <Box sx={{width:'23.5%'}}>
                <Typography sx={{fontWeight:'bold', textTransform:'uppercase', display:'flex ',alignItems:'center',fontSize:'small'}}>Refundable Amount (in rs.)<span style={{color:'red'}}>*</span></Typography>
                <Controller
                    name='refundableAmount'
                    control={control}
                    rules={{
                        required: "required", // This can be optional if only required when refundable is true
                        validate: (value) => {
                          const isRefundable = watch('isRefundable');
                          const floatValue = parseFloat(value);
                          if (isRefundable && floatValue <= 0) {
                            return "Refundable Amount should be more than Rs. 0.00 ";
                          }
                          return true; // Return true if no validation errors
                        }
                      }}
                    render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        type="number"
                        value={formatValue(field.value)}
                        disabled={services?.length=='0' || !isRefundable}
                        onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
                            field.onChange(formatValue(inputValue));
                        }
                        }}
                        onBlur={(e) => {
                            field.onBlur();
                            field.onChange(formatValue(e.target.value));
                        }}
                        // type="text"
                        inputMode="decimal"
                        size="small"
                        fullWidth
                        inputProps={{
                            style: { textAlign: 'center', height: '1rem' },
                            min: 0,
                            step: 0.01 // Allow two decimal places
                        }}
                        error={!!error}
                    /> 
                    )}  
                />                    
            </Box>
            <Box sx={{width:'23.5%'}}>
                <Typography sx={{fontWeight:'bold', textTransform:'uppercase', display:'flex ',alignItems:'center',fontSize:'small'}}>Refundable Duration (in Days)<span style={{color:'red'}}>*</span></Typography>                        
                <Controller
                    name='refundableDuration'
                    control={control}
                    rules={{
                        required: "required", // This can be optional if only required when refundable is true
                        validate: (value) => {
                          const isRefundable = watch('isRefundable');
                          const floatValue = parseFloat(value);
                          if (isRefundable && floatValue <= 0) {
                            return "Refundable Amount should be more than Rs. 0.00 ";
                          }
                          return true; // Return true if no validation errors
                        }
                      }}
                    render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        type="number"
                        value={formatValue(field.value)}
                        disabled={services?.length=='0' || !isRefundable}
                        onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
                            field.onChange(formatValue(inputValue));
                        }
                        }}
                        onBlur={(e) => {
                            field.onBlur();
                            field.onChange(formatValue(e.target.value));
                        }}
                        // type="text"
                        inputMode="decimal"
                        size="small"
                        fullWidth
                        inputProps={{
                            style: { textAlign: 'center', height: '1rem' },
                            min: 0,
                            step: 0.01 // Allow two decimal places
                        }}
                        error={!!error}
                    /> 
                    )}  
                />          
            </Box>    
        </Box>                  
    </Box>
  );
}

export default ServiceCharges;
