// import { commonRequest } from "../../services/ApiCall";
// import { Helpers } from "../../services/Helpers";

import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";

export const ProviderServices = {
    subCategoryList : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/service-category/getSubCategories/${data.categoryId}`,{}, Helpers.token());
    },

    serviceList: async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/service-master/getServicesBySubcategory/${data.subCategoryId}`,{}, Helpers.token());
    },

    createUserProfile: async(data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/admin/user/addUserProfile`,data, Helpers.token());
    },

    updateUserBusinessDetail: async(data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/user/addUpdateBusinessDetails`,data, Helpers.token());
    },

    updateUserServiceDetail: async(data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/admin/provider-services/`,data, Helpers.token());
    },

    updateUserBankDetail: async(data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/bank-detail`,data, Helpers.token());
    },

    updateTimeSlot: async(data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/provider-time-slots/`,data, Helpers.token());
    },

    fetchProviderServices: async(data) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/provider-services/list?page=${data.page}&limit=${data.pageSize}&providerId=${data.providerId}&searchKey=`,{}, Helpers.token());
    },

    updateProviderServices: async(data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/api/admin/provider-services/1`,data, Helpers.token());
    },

    fetchProviderTimeSlots: async(providerId) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/api/provider-time-slots/${providerId}`,{}, Helpers.token());
    },

    fetchProviderDetailsById: async(providerId) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/user?userId=${providerId}`,{}, Helpers.token());
    },

    fetchProviderList: async(data) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/user/list?page=${data.page}&limit=${data.limit}&searchKey=${data.filters.searchKey}&sortBy=createdAt&sortType=DESC`,{}, Helpers.token());
    }
    
}