import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { createContext, useEffect, useState } from "react";
import { Auth } from "../../../services/AuthServices";
import { LocalStore } from "../../../storage/authStore";
import Loading from "../Loading";
import LoginModule from "./LoginModule";
import Otp from "./Otp";
import LogoSection from "../LogoSection";


const UserContext = createContext();
export default function Login() {
    const navigate = useNavigate();
    const [isLoading,setIsLoading] = useState(false);
    const [step,setStep] = useState(0);

    useEffect(() => {
        if(LocalStore.isLogin()){
            navigate("/admin")
        }
    });
    
    const [mobile, setMobile] = useState("");
    
    const sendOtp = async (e) => {
        e.preventDefault();
        var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
        if (!pattern.test(mobile)) {
            toast.error("Please Enter Number Only");
        } else if (mobile.length != 10) {
            toast.error("Mobile should be 10 number.");
        }else{
            setIsLoading(true);
            console.log("submitted Data:",{mobileNumber: mobile})
            const response = await Auth.checkMobile({mobileNumber: mobile});
            console.log("mobile number submission response:",response);
            if(response.success){
                const userType = response.data.userDetail.userType;
                const user = {
                    id:response.data.userDetail.id,
                    mobileNumber:response.data.userDetail.mobileNumber.replace(/\D/g, ''),
                    userType:response.data.userDetail.userType
                }
                if(userType=='1' || userType=='3') {
                    localStorage.setItem('isUser', JSON.stringify({status: true, user: user}));
                    setStep(1);
                }else{
                    toast.error("You are not authorized");
                }              
                
                setIsLoading(false);
            }else{               
               toast.error(response.message);
               setIsLoading(false);
            }
           
        }        
    };

    return (
        <UserContext.Provider value={{isLoading,setIsLoading,sendOtp,setMobile,step,setStep}}>
            <Container component="main" maxWidth="lg">
                <Box >
                    <Grid container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ minHeight: '90vh' }}>
                        <CssBaseline />

                        <Grid
                            item
                            // xs={12}
                            // sm={8}
                            // md={5}
                            component={Paper}
                            elevation={6}
                            square
                            sx={{
                                width: { xs: "100%", sm: "75%", md: "50%" },  // Responsive widths
                                maxWidth: "600px",  // You can add a maximum width to control it on larger screens
                                // padding: 2,  // Add padding to improve spacing
                                borderRadius:'1rem'
                            }}
                        >
                        <LogoSection/>
                        {step == 0 && <LoginModule/>}
                        {step == 1 && <Otp/>}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </UserContext.Provider>
        
    );
}

export {UserContext}