import React, { useContext } from 'react';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../Loading';
import { useNavigate } from 'react-router-dom';
import LogoSection from '../LogoSection';
import { UserContext } from './MobileOtpModule';

const LoginModule = () => {
    const navigate = useNavigate();
    const {isLoading,setIsLoading,sendOtp,setMobile} = useContext(UserContext);
  return (
    <>               
        <Paper
            sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p:'3%',
                borderRadius:'.75rem'
            }}
        >
            <Box sx={{textAlign:'left',width:'85%'}}>
                <Typography component="h1" variant="h5">
                    Hello, there
                </Typography>
                <Typography component="h1" variant="h5">
                    Enter Mobile Number
                </Typography>
            </Box>
            <Box
                component="form"
                noValidate
                sx={{ mt: 1 ,width:'85%'}}
                textAlign={'center'}

            >
                <TextField
                    margin="normal"
                    required
                    // sx={{width:'85%'}}
                    fullWidth
                    size='small'
                    id="mobile_number"
                    label="Mobile Number"
                    name="mobile_number"
                    autoComplete="mobile_number"
                    autoFocus
                    onChange={(e)=>setMobile(e.target.value)}
                />

                <Box sx={{textAlign:'center'}}>
                    <Button
                        type="submit"
                        // size='small'
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 ,width:'fit-content'}}
                        onClick={sendOtp}
                    >
                        Get Otp
                    </Button>
                </Box>
            </Box>
        </Paper>
                    
        <ToastContainer />
        {isLoading && <Loading/>}
    </>
  );
}

export default LoginModule;
