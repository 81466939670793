import React, { useEffect, useState } from 'react';
import { useForm, Controller ,useFormContext} from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Box,
  Chip,
  Stack,
  Divider,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Card,FormControlLabel,Checkbox,CardContent,
  ListItemText
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BookingService } from '../../Booking/Service';

const BasicInfo = () => {
    const { control, handleSubmit, setValue, formState: { errors },watch } = useFormContext();
    const [categoryList,setCategoryList] = useState([]);
    useEffect(()=>{
      const fetchCategoryList= async()=>{
          try {
            const categoryListResponse = await BookingService.categoryList();
            // console.log("category list Response:",categoryListResponse);
            if(categoryListResponse.success===1){
              setCategoryList(categoryListResponse.data)
            }
          } catch (error) {
            
          }
        }
        fetchCategoryList();
    },[]);
  return (
    <Grid item xs={12} lg={7}>
      <Card sx={{my:0.5}}>
        <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
            <Typography sx={{fontWeight:'bold'}}>Basic Info</Typography>
        </Box>
        <Box sx={{p:'2%'}}>
          <Box sx={{display:'flex',gap:'2%'}}>
            <Box sx={{my:'1%'}}>
              <Typography sx={{width:'60%' ,fontWeight:'bold', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>First Name<span style={{color:'red'}}>*</span></Typography>
              <Controller
                name='firstName'
                control={control}
                rules={{
                  required: "required",
                  pattern:{
                      value:/^[a-zA-Z]*$/,
                      message:"only alphabets allowed"
                  },
                  minLength:{
                      value:3,
                      message:"minimum 3 digit"
                  },
                  maxLength:{
                      value:30,
                      message:"maximum 30 digit"
                  }
                }}
                render={({field,fieldState:{error}})=>(
                  <TextField
                    {...field}
                    sx={{}}
                    size='small'
                    InputProps={{
                        style:{
                            // height:'2rem'
                        },
                        // inputProps:{
                        //     style:{textTransform:"uppercase"},
                        // }   
                    }}
                    error={!!error}
                    // helperText={error && error?.message}
                  /> 
                )}  
              />                    
            </Box>
            <Box sx={{my:'1%'}}>
              <Typography sx={{width:'60%' ,fontWeight:'bold', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Middle Name</Typography>
              <Controller
                name='middleName'
                control={control}
                rules={{
                  // required: "required",
                  pattern:{
                      value:/^[a-zA-Z]*$/,
                      message:"only alphabets allowed"
                  },
                  minLength:{
                      value:3,
                      message:"minimum 3 digit"
                  },
                  maxLength:{
                      value:30,
                      message:"maximum 30 digit"
                  }
                }}
                render={({field,fieldState:{error}})=>(
                  <TextField
                    {...field}
                    sx={{}}
                    size='small'
                    InputProps={{
                        style:{
                            // height:'2rem'
                        },
                        // inputProps:{
                        //     style:{textTransform:"uppercase"},
                        // }   
                    }}
                    error={!!error}
                    // helperText={error && error?.message}
                  /> 
                )}  
              />                    
            </Box>
            <Box sx={{my:'1%'}}>
              <Typography sx={{width:'60%' ,fontWeight:'bold', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Last Name</Typography>
              <Controller
                name='lastName'
                control={control}
                rules={{
                  // required: "required",
                  pattern:{
                      value:/^[a-zA-Z]*$/,
                      message:"only alphabets allowed"
                  },
                  minLength:{
                      value:3,
                      message:"minimum 3 digit"
                  },
                  maxLength:{
                      value:30,
                      message:"maximum 30 digit"
                  }
                }}
                render={({field,fieldState:{error}})=>(
                  <TextField
                    {...field}
                    sx={{}}
                    size='small'
                    InputProps={{
                        style:{
                            // height:'2rem'
                        },
                        // inputProps:{
                        //     style:{textTransform:"uppercase"},
                        // }   
                    }}
                    error={!!error}
                    // helperText={error && error?.message}
                  /> 
                )}  
              />                    
            </Box>    
          </Box>           
          <Box sx={{display:'flex',gap:'2%',my:'1%'}}>
            <Box sx={{flex:2,}}>
              <Typography sx={{width:'60%' ,fontWeight:'bold', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Email<span style={{color:'red'}}>*</span></Typography>
              <Controller
                name='email'
                control={control}
                rules={{
                    required:'enter Email',
                    pattern:{
                        value:/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                        message:"Email is not Valid"
                    }
                }}
                render={({field,fieldState:{error}})=>(
                    <TextField
                        {...field}
                        fullWidth
                        // sx={{width:'80%'}}
                        size='small'
                        InputProps={{
                            style:{
                                // height:'2rem'
                            },
                        inputProps:{
                            style:{textTransform:'lowercase'}}
                        }}
                        error={!!error}
                        // helperText={error && error?.message}
                    /> 
                )}                 
              />                
            </Box>
            {/* <Box>
              <Typography sx={{width:'65%' ,fontWeight:'bold', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Contact Number <span style={{color:'red'}}>*</span></Typography>
              <Controller
                name='mobileNumber'
                control={control}
                rules={{
                    pattern:{
                        value:/[1-9]{1}[0-9]{9}/,
                        message:"only numeric : not starting with 0"
                    },
                    minLength:{
                        value:10,
                        message:"mobileNumber should be of 10 digit"
                    },
                    maxLength:{
                        value:10,
                        message:"mobileNumber should be of 10 digit"
                    }
                }}
                render={({field,fieldState:{error}})=>(
                    <TextField
                        {...field}
                        // sx={{width:'58%'}}
                        size='small'
                        error={!!error}
                        // helperText={error && error?.message}
                    />
                )}                       
              />
            </Box> */}
          </Box>
          <Box sx={{display:'flex' , gap:'2%',my:'1%'}}>
            <Box sx={{flex:1}}>
              <Typography sx={{width:'60%' ,fontWeight:'bold', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Date Of Birth<span style={{color:'red'}}>*</span></Typography>
              <Controller
                name="dob"
                control={control}
                rules={{
                  required: "Date of Birth is required",
                  validate: (value) => {
                    if (!value) return "This field is required";
                    const date = dayjs(value);
                    const today = dayjs().startOf('day');
                    const eighteenYearsAgo = today.subtract(18, 'year');
                    
                    if (!date.isValid()) return "Invalid date";
                    if (date.isAfter(today)) return "Date of Birth must be in the past";
                    if (date.isAfter(eighteenYearsAgo)) return "must be at least 18 years old";
                    
                    return true;
                  }
                }}
                render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      
                      {...restField}
                      value={value ? dayjs(value) : null}
                      onChange={(newValue) => {
                        // Store the date in YYYY-MM-DD format for database compatibility
                        onChange(newValue ? newValue.format("YYYY-MM-DD") : null);
                      }}
                      format="DD-MM-YYYY" // Display format for user
                      maxDate={dayjs()}
                      slotProps={{
                        textField: {
                          error: !!error,
                          helperText: error?.message,
                          fullWidth: true,
                          required: true,
                          size: 'small',
                        }
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </Box>
            <Box sx={{flex:1}}>
              <Typography sx={{width:'60%' ,fontWeight:'bold', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Gender <span style={{color:'red'}}>*</span></Typography>
              <Controller
                  name='gender'
                  control={control}
                  rules={{required:'select gender'}}
                  render={({field,fieldState:{error}})=>(
                      <FormControl fullWidth>
                          <Select
                              {...field}
                              size='small'
                              // sx={{height:'2rem'}}
                              error={!!error}
                              // helperText={error && error?.message}
                          >
                              <MenuItem sx={{textTransform:'capitalize'}} value='Male'>male</MenuItem>
                              <MenuItem sx={{textTransform:'capitalize'}}value='Female'>female</MenuItem>
                              <MenuItem sx={{textTransform:'capitalize'}} value='Other'>other</MenuItem>
                          </Select>
                      </FormControl>
                  )}  
              />
            </Box>
            
          </Box>
          <Box sx={{display:'flex' , gap:'2%'}}>
            <Box sx={{flex:2}}>
              <Typography sx={{fontWeight:'bold', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Service Categories <span style={{color:'red'}}>*</span></Typography>
              <Controller
                  name='serviceCategories'
                  control={control}
                  rules={{required:'select category'}}
                  defaultValue={[]}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl size="small" sx={{ minWidth: "100%" }}>
                      <Select
                        {...field}
                        size='small'
                        multiple
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip
                              key={value}
                              label={categoryList.find(opt => opt.id.toString() === value)?.title}
                              size="small"
                              onDelete={(event) => {
                                event.stopPropagation();
                                const newValue = field.value.filter(item => item !== value);
                                field.onChange(newValue);
                              }}
                              deleteIcon={
                                <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                              }
                            />
                            ))}
                          </Box>
                        )}
                        error={!!error}
                        onChange={(event) => {
                          const selectedValues = event.target.value.map(String);
                          field.onChange(selectedValues);
                        }}
                        value={field.value || []}
                        sx={{
                          '& .MuiSelect-select': {
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                            // minHeight: '2rem',
                            alignItems: 'center'
                          }
                        }}
                      >
                        {categoryList.map((option) => (
                          <MenuItem key={option.id} value={option.id.toString()}>
                            <Checkbox checked={field.value.indexOf(option.id.toString()) > -1} />
                            <ListItemText primary={option.title} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
              />
            </Box>
            <Box sx={{flex:1}}>
              <Typography sx={{fontWeight:'bold', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>waive Visit Charge <span style={{color:'red'}}>*</span></Typography>
              <Controller
                name='waiveVisitCharge'
                control={control}
                rules={{required:'enter waive Visit Charge'}}
                defaultValue={[]}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size='small'
                    error={!!error}
                  />
                )}
              />
            </Box>
          </Box>  
          <Box>
            {/* {watch('serviceCategories').map(id => categoryList.find(opt => opt.id === id)?.title).join(', ')} */}
          </Box>
        </Box>
      </Card>
    </Grid>
  );
}

export default BasicInfo;
